/**
 * @Copyright 2021 @DigiNet
 * @Author QUOCUY
 * @Create 4/29/2021
 * @Example
 */

export const types = {
    W29F2070_LOAD_GRID: "W29F2070_LOAD_GRID",
    W29F2070_DELETE_ROW: "W29F2070_DELETE_ROW",
    W29F2070_EXPORT: "W29F2070_EXPORT",
    W29F2070_GET_ISEXPORT: "W29F2070_GET_ISEXPORT",
};

export function loadGrid(params, cb) {
    return {
        type: types.W29F2070_LOAD_GRID,
        params,
        cb,
    };
}

export function deleteRow(params, cb) {
    return {
        type: types.W29F2070_DELETE_ROW,
        params,
        cb,
    };
}

export function exportData(params, cb) {
    return {
        type: types.W29F2070_EXPORT,
        params,
        cb,
    };
}

export function getIsExport(cb) {
    return {
        type: types.W29F2070_GET_ISEXPORT,
        cb,
    };
}
