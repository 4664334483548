/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
export const types = {
   
    W29F2045_GET_FORMS: "W29F2045_GET_FORMS",
    W29F2045_GET_FORMS_SUCCESS: "W29F2045_GET_FORMS_SUCCESS",
    W29F2045_GET_FORMS_MASTER: "W29F2045_GET_FORMS_MASTER",
    W29F2045_GET_FORMS_MASTER_SUCCESS: "W29F2045_GET_FORMS_MASTER_SUCCESS",
    W29F2045_SAVE_FORMS: "W29F2045_SAVE_FORMS",
    W29F2045_SAVE_FORMS_SUCCESS: "W29F2045_SAVE_FORMS_SUCCESS",
    W29F2045_EDIT_FORMS: "W29F2045_EDIT_FORMS",
    W29F2045_EDIT_FORMS_SUCCESS: "W29F2045_EDIT_FORMS_SUCCESS",
   
};

export function getForms(params, cb) {
    return {
        type: types.W29F2045_GET_FORMS,
        params,
        cb
    }
}
export function getFormsMaster(params, cb) {
    return {
        type: types.W29F2045_GET_FORMS_MASTER,
        params,
        cb
    }
}
export function saveForms(params, cb) {
    return {
        type: types.W29F2045_SAVE_FORMS,
        params,
        cb
    }
}
export function editForms(params, cb) {
    return {
        type: types.W29F2045_EDIT_FORMS,
        params,
        cb
    }
}
