/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/7/2022
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest, put } from "redux-saga/effects";
import { types } from "../../W2X/W29F2300/W29F2300_actions";
import { delay } from "redux-saga";

export default function W29F2300Sagas() {
    return [
        watchGetCboShifts(),
        watchGetSalaryGroups(),
        watchGetCriteria(),
        watchGetGridDetail(),
        watchSave(),
    ];
}

export function* getCboShifts(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w29f2000/list-of-shift", data.params);
        if (res && res.data) {
            yield put({
                type: types.W29F2300_GET_CBO_SHIFT_SUCCESS,
                data: res.data
            });
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('get Cbo Shifts error');
    }
}

export function* watchGetCboShifts() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2300_GET_CBO_SHIFT,
            getCboShifts
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getSalaryGroups(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w29f2300/load-salary-group");
        if (res && res.data) {
            yield put({
                type: types.W29F2300_GET_SALARY_GROUPS_SUCCESS,
                data: res.data
            });
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('get Salary Groups error');
    }
}

export function* watchGetSalaryGroups() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2300_GET_SALARY_GROUPS,
            getSalaryGroups
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCriteria(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w29f2300/load-criteria", data.params);
        if (res && res.data) {
            yield put({
                type: types.W29F2300_GET_CRITERIA_SUCCESS,
                data: res.data
            });
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('get Criteria error');
    }
}

export function* watchGetCriteria() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2300_GET_CRITERIA,
            getCriteria
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getGridDetail(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w29f2300/load-grid-detail", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('get Grid Detail error');
    }
}

export function* watchGetGridDetail() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2300_GET_GRID_DETAIL,
            getGridDetail
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const res = yield Api.post("/w29f2300/add", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('save error');
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(
            types.W29F2300_SAVE,
            save
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
