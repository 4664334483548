/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 1/6/2022
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import { types } from './W29F2038_actions';
import { delay } from 'redux-saga';

export default function W29F2038Sagas() {
    return [watchGetData(), watchSaveApproval()];
}

export function* getData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2038/load-gird', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get data error');
    }
}

export function* watchGetData() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2038_GET_DATA, getData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* saveApproval(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2038/approval', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save error');
    }
}

export function* watchSaveApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2038_SAVE_APPROVAL, saveApproval);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
