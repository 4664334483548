/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/20/2019
 * @Example
 */

import Api from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2045_actions";
import { delay } from "redux-saga";

export default function W29F2045Sagas() {
    return [
        
        watchGetForms(),
        watchGetFormsMaster(),
        watchSaveForms(),
        watchEditForms(),
      
    ];
}

export function* getForms(data) {
    try {
        yield delay(300);
        
        const response = yield Api.put('/w29f2045/load-grid', data.params);
        // const response = {data : {
        //     EmployeeID:"%00003",
        //     AttendanceTypeName:"AttendanceTypeName",
        //         ShiftName:"ShiftName",
        //         AttendanceDate:new Date(),
        //         Note:"Note",
        //         TimeOn1:"22:01",
        //         TimeOff1:"22:1",
        //         IsUpdate1:1
        // }}
        if (response && response.data) {
          
            yield put({ type: types.W29F2045_GET_FORMS_MASTER_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form error');
    }

}

export function* watchGetForms() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2045_GET_FORMS, getForms);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
export function* getFormsMaster(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2045/load-form-master', data.params);
        if (response && response.data) {
          
            yield put({ type: types.W29F2045_GET_FORMS_MASTER_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form error');
    }

}

export function* watchGetFormsMaster() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2045_GET_FORMS_MASTER, getFormsMaster);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
export function* saveForms(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2045/save', data.params);
        if (response && response.data) {
          
            yield put({ type: types.W29F2045_SAVE_FORMS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save form error');
    }

}

export function* watchSaveForms() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2045_SAVE_FORMS, saveForms);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* editForms(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2045/edit', data.params);
        if (response && response.data) {
          
            yield put({ type: types.W29F2045_EDIT_FORMS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('edit form error');
    }

}

export function* watchEditForms() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2045_EDIT_FORMS, editForms);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}