/**
 * @copyright 2022 @ DigiNet
 * @author THANHAN
 * @create 10/17/2022
 * @Example
 */

export const types = {
    W29F2309_LOAD_FORM: "W29F2309_LOAD_FORM",
    W29F2309_LOAD_FORM_SUCCESS: "W29F2309_LOAD_FORM_SUCCESS",
    W29F2309_APPROVAL: "W29F2309_APPROVAL",
    W29F2309_APPROVAL_SUCCESS: "W29F2309_APPROVAL_SUCCESS",
    W29F2309_CHECK: "W29F2309_CHECK",
    W29F2309_CHECK_SUCCESS: "W29F2309_CHECK_SUCCESS",
    W29F2309_GET_GRID_SETTING: "W29F2309_GET_GRID_SETTING",
    W29F2309_GET_GRID_SETTING_SUCCESS: "W29F2309_GET_GRID_SETTING_SUCCESS",
    W29F2309_CANCEL_APPROVAL: "W29F2309_CANCEL_APPROVAL",
    W29F2309_CANCEL_APPROVAL_SUCCESS: "W29F2309_CANCEL_APPROVAL_SUCCESS",

}

export function loadForm(params, cb) {
    return {
        type: types.W29F2309_LOAD_FORM,
        params,
        cb
    }
}

export function getGridSetting(cb) {
    return {
        type: types.W29F2309_GET_GRID_SETTING,
        cb
    }
}
// approval
export function approval(params, cb) {
    return {
        type: types.W29F2309_APPROVAL,
        params,
        cb
    }
}
// check
export function check(params, cb) {
    return {
        type: types.W29F2309_CHECK,
        params,
        cb
    }
}
//
export function cancelApproval(){
    return {
        type: types.W29F2309_CANCEL_APPROVAL
    }
}