/**
 * @copyright 2022 @ DigiNet
 * @author THANHAN
 * @create 10/10/2022
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest, put } from "redux-saga/effects";
import { types } from "./W29F2306_actions";
import { delay } from "redux-saga";

export default function W29F2306Sagas() {
    return [
        watchLoadGrid(),
        watchSave(),
        watchCpreCheck(),
        watchCbetCheck(),
        watchCaftCheck(),
    ];
}

//load grid
export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2306/load-detail-grid', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2306_LOAD_GRID_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load grid error', e);
    }
}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2306_LOAD_GRID, loadGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//save
export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2306/save', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2306_SAVE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save error', e);
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2306_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//cpre
export function* cpreCheck(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2306/cpre-ot-check', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2306_CPRE_CHECK_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check cpre error', e);
    }
}

export function* watchCpreCheck() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2306_CPRE_CHECK, cpreCheck);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//cbet
export function* cbetCheck(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2306/cbet-ot-check', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2306_CBET_CHECK_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check cbet error', e);
    }
}

export function* watchCbetCheck() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2306_CBET_CHECK, cbetCheck);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//caft
export function* caftCheck(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2306/caft-ot-check', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2306_CAFT_CHECK_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check caft error', e);
    }
}

export function* watchCaftCheck() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2306_CAFT_CHECK, caftCheck);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}