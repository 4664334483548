/**
 * @copyright 2022 @ DigiNet
 * @author THANHAN
 * @create 10/10/2022
 * @Example
 */

export const types = {
    W29F2306_LOAD_GRID: "W29F2306_LOAD_GRID",
    W29F2306_LOAD_GRID_SUCCESS: "W29F2306_LOAD_GRID_SUCCESS",
    W29F2306_SAVE: "W29F2306_SAVE",
    W29F2306_SAVE_SUCCESS: "W29F2306_SAVE_SUCCESS",
    W29F2306_CPRE_CHECK: "W29F2306_CPRE_CHECK",
    W29F2306_CPRE_CHECK_SUCCESS: "W29F2306_CPRE_CHECK_SUCCESS",
    W29F2306_CBET_CHECK: "W29F2306_CBET_CHECK",    
    W29F2306_CBET_CHECK_SUCCESS: "W29F2306_CBET_CHECK_SUCCESS",
    W29F2306_CAFT_CHECK: "W29F2306_CAFT_CHECK",    
    W29F2306_CAFT_CHECK_SUCCESS: "W29F2306_CAFT_CHECK_SUCCESS",
};

export function loadGrid(params, cb) {
    return {
        type: types.W29F2306_LOAD_GRID,
        params,
        cb
    }
}

export function save(params, cb) {
    return {
        type: types.W29F2306_SAVE,
        params,
        cb
    }
}

export function cpre(params, cb) {
    return {
        type: types.W29F2306_CPRE_CHECK,
        params,
        cb
    }
}

export function cbet(params, cb) {
    return {
        type: types.W29F2306_CBET_CHECK,
        params,
        cb
    }
}

export function caft(params, cb) {
    return {
        type: types.W29F2306_CAFT_CHECK,
        params,
        cb
    }
}