

import Api from '../../../services/api';
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2305_actions";
import { delay } from "redux-saga";

export default function W29F2305Sagas() {
    return [
        watchLoadGridData(),
        watchGetAppStatus(),
        watchGetSalEmpGroup(),
        watchDeleteData(),
    ];
}
export function* loadGridData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2305/load-grid', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('W29F2305 get grid data error');
    }
}

export function* watchLoadGridData() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2305_GET_GRID_DATA, loadGridData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getSalEmpGroup(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w29f2305/get-sal-emp-group");
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("W29F2305 SalEmpGroup error", e);
    }
}

export function* watchGetSalEmpGroup() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2305_GET_SAL_EMP_GROUP, getSalEmpGroup);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboStatus(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w29f2305/get-app-status", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log("W29F2305 SalEmpGroup error", e);
    }
}

export function* watchGetAppStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2305_GET_APP_STATUS, getCboStatus);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteData(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w29f2305/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete data error");
    }
}

export function* watchDeleteData() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2305_GET_DELETE, deleteData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}