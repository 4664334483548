/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/7/2022
 * @Example
 */

export const types = {
    W29F2120_LOAD_DATE: 'W29F2120_LOAD_DATE',
    W29F2120_LOAD_LEAVE_TYPE: 'W29F2120_LOAD_LEAVE_TYPE',
    W29F2120_LOAD_TIME_KEEPING: 'W29F2120_LOAD_TIME_KEEPING',
    W29F2120_SAVE: 'W29F2120_SAVE',

};

export function loadDate(params, cb) {
    return {
        type: types.W29F2120_LOAD_DATE,
        params,
        cb
    }
}

export function loadLeaveType(params, cb) {
    return {
        type: types.W29F2120_LOAD_LEAVE_TYPE,
        params,
        cb
    }
}

export function loadTimeKeeping(params, cb) {
    return {
        type: types.W29F2120_LOAD_TIME_KEEPING,
        params,
        cb
    }
}

export function save(params, cb) {
    return {
        type: types.W29F2120_SAVE,
        params,
        cb
    }
}