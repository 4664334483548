/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/25/2021
 * @Example
 */

export const types = {
    W29F2086_GET_FORM: "W29F2086_GET_FORM",
    W29F2086_LOAD_CHOOSE_EMPLOYEE: "W29F2086_LOAD_CHOOSE_EMPLOYEE",
    W29F2086_SAVE: "W29F2086_SAVE",
    W29F2086_DELETE_ROW: "W29F2086_DELETE_ROW",
    W29F2086_DELETE_09T6668: "W29F2086_DELETE_09T6668",
};

export function getForm(params, cb) {
    return {
        type: types.W29F2086_GET_FORM,
        params,
        cb,
    };
}

export function loadChooseEmployee(params, cb) {
    return {
        type: types.W29F2086_LOAD_CHOOSE_EMPLOYEE,
        params,
        cb,
    };
}

export function save(params, cb) {
    return {
        type: types.W29F2086_SAVE,
        params,
        cb,
    };
}

export function deleteRow(params, cb) {
    return {
        type: types.W29F2086_DELETE_ROW,
        params,
        cb,
    };
}

export function deleteD09T6668(params, cb) {
    return {
        type: types.W29F2086_DELETE_09T6668,
        params,
        cb,
    };
}
