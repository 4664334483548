/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/23/2019
 * @Example 
 */

import { types } from "./W29F2040_actions";

const initialState = {
    getForms:[],
    getCboOrgCharts:[],
    getCboShifts:[],
    
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W29F2040_GET_FORM_SUCCESS:
            return { ...state, getForms: action.data };
        case types.W29F2040_GET_CBO_ORG_CHARTS_SUCCESS:
            return { ...state, getCboOrgCharts: action.data };
        case types.W29F2040_GET_CBO_SHIFTS_SUCCESS:
            return { ...state, getCboShifts: action.data };
        
        default:
            return state;
    }
}
