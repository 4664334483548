/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
export const types = {
    W29F2040_GET_FORM: "W29F2040_GET_FORM",
    W29F2040_GET_FORM_SUCCESS: "W29F2040_GET_FORM_SUCCESS",

    W29F2040_GET_CBO_ORG_CHARTS: "W29F2040_GET_CBO_ORG_CHARTS",
    W29F2040_GET_CBO_ORG_CHARTS_SUCCESS: "W29F2040_GET_CBO_ORG_CHARTS_SUCCESS",

    W29F2040_GET_CBO_SHIFTS: "W29F2040_GET_CBO_SHIFTS",
    W29F2040_GET_CBO_SHIFTS_SUCCESS: "W29F2040_GET_CBO_SHIFTS_SUCCESS",

    W29F2040_DELETE_ACT_OT_HOUR: "W29F2040_DELETE_ACT_OT_HOUR",
   
};




export function getForms(params,cb) {
    return {
        type: types.W29F2040_GET_FORM,
        params,
        cb,
    }
}
export function getCboOrgCharts(params,cb) {
    return {
        type: types.W29F2040_GET_CBO_ORG_CHARTS,
        params,
        cb,
    }
}
export function getCboShifts(params,cb) {
    return {
        type: types.W29F2040_GET_CBO_SHIFTS,
        params,
        cb,
    }
}
export function deleteActOTHour(params,cb) {
    return {
        type: types.W29F2040_DELETE_ACT_OT_HOUR,
        params,
        cb,
    }
}