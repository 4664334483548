/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/25/2021
 * @Example
 */
import Api from "../../../services/api";
import { cancel, take, takeLatest, takeEvery } from "redux-saga/effects";
import { types } from "./W29F2086_actions";
import { delay } from "redux-saga";

export default function W29F2086Sagas() {
    return [watchGetForm(), watchLoadChooseEmployee(), watchSave(), watchDeleteRow(), watchDeleteD09T6668()];
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2086/load-gird", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeEvery(types.W29F2086_GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadChooseEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2086/choose-date", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load choose employee error");
    }
}

export function* watchLoadChooseEmployee() {
    while (true) {
        const watcher = yield takeEvery(types.W29F2086_LOAD_CHOOSE_EMPLOYEE, loadChooseEmployee);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2086/save", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save error");
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2086_SAVE, save);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w29f2086/delete-d09t6668", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete row error");
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2086_DELETE_ROW, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteD09T6668(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w29f2086/delete-d09t6668", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete row error");
    }
}

export function* watchDeleteD09T6668() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2086_DELETE_09T6668, deleteD09T6668);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
