/**
 * @Copyright 2022 @DigiNet
 * @Author DANGTRIEU
 * @Create 11/10/2022
 * @Example
 */
 export const types = {
    W29F2305_GET_GRID_DATA: "W29F2305_GET_GRID_DATA",
    W29F2305_GET_APP_STATUS: "W29F2305_GET_APP_STATUS",
    W29F2305_GET_SAL_EMP_GROUP: "W29F2305_GET_SAL_EMP_GROUP",
    W29F2305_GET_DELETE: "W29F2305_GET_DELETE"
};

export const loadGridData = (params,cb) => {
    return {
        type: types.W29F2305_GET_GRID_DATA,
        params,
        cb,
    };
};

export const getAppStatus = (params, cb) => {
    return {
        type: types.W29F2305_GET_APP_STATUS,
        params,
        cb,
    };
};

export const getSalEmpGroup = (cb) => {
    return {
        type: types.W29F2305_GET_SAL_EMP_GROUP,
        cb,
    };
};

export const deleteDaTa = (params, cb) => {
    return {
        type: types.W29F2305_GET_DELETE,
        params,
        cb,
    };
};