/**
 * @copyright 2023 @ DigiNet
 * @author TRANGHOANG
 * @create 18/01/2023
 * @Example 
 */
export const types = {
    W29F2060_LOAD_FORM: "W29F2060_LOAD_FORM",
    W29F2060_LOAD_SHIFT: "W29F2060_LOAD_SHIFT",
    W29F2060_DELETE_ROW: "W29F2060_DELETE_ROW",
    W29F2060_EXPORT_LEAVE_OF_ABSENCE_FORM: "W29F2060_EXPORT_LEAVE_OF_ABSENCE_FORM",
};

export function loadForm(params, cb) {
    return {
        type: types.W29F2060_LOAD_FORM,
        params,
        cb
    }
};

export function loadShift(params, cb) {
    return {
        type: types.W29F2060_LOAD_SHIFT,
        params,
        cb
    }
}

export function deleteRow(params, cb) {
    return {
        type: types.W29F2060_DELETE_ROW,
        params,
        cb
    }
}

export function exportLeaveOfAbsenceForm(params,cb) {
    return {
        type: types.W29F2060_EXPORT_LEAVE_OF_ABSENCE_FORM,
        params,
        cb
    }
}