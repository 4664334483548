/**
 * @Copyright 2021 @DigiNet
 * @Author NAMDANG
 * @Create 9/17/2021
 * @Example
 */

import { delay } from "redux-saga";
import {cancel, take, takeLatest} from "redux-saga/effects";
import Api from "../../../services/api";
import { types } from "./W29F2081_actions";

export default function W29F2081Sagas() {
    return [watchGetDataForm(), watchGetShift(), watchSave()];
}

export function* getDataForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2081/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data form error");
    }
}

export function* watchGetDataForm() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2081_GET_DATA_FORM, getDataForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getShift(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2081/load-shift", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get shift error");
    }
}

export function* watchGetShift() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2081_GET_SHIFT, getShift);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2081/add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save data error");
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2081_SAVE, save);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
