/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 1/6/2022
 * @Example
 */
export const types = {
    W29F2038_GET_DATA: 'W29F2038_GET_DATA',
    W29F2038_SAVE_APPROVAL: 'W29F2038_SAVE_APPROVAL',
};

export function getData(params, cb) {
    return {
        type: types.W29F2038_GET_DATA,
        params,
        cb,
    };
}

export function saveApproval(params, cb) {
    return {
        type: types.W29F2038_SAVE_APPROVAL,
        params,
        cb,
    };
}
