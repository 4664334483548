/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/8/2022
 * @Example
 */
import { types } from "./W29F2300_actions";

const initialState = {
    getCboShifts: [],
    getSalaryGroups: [],
    getCriteria: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W29F2300_GET_CBO_SHIFT_SUCCESS: {
            return {...state, getCboShifts: action.data};
        }
        case types.W29F2300_GET_SALARY_GROUPS_SUCCESS: {
            return {...state, getSalaryGroups: action.data};
        }
        case types.W29F2300_GET_CRITERIA_SUCCESS: {
            return {...state, getCriteria: action.data};
        }

        default:
            return state;
    }
}
