import Api from "../../../services/api";
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2085_actions";
import { delay } from "redux-saga";
import _ from "lodash";

export default function W29F2085Sagas() {
    return [
        watchLoadDataGrid(),
        watchLoadApprovalHistory(),
        watchDeleteRow(),
        watchOnCancel(),
    ];
}

export function* loadDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2085/load-form", data.params);
        const resData = _.get(response, "data", null);
        const messageErr = _.get(response, "Message", _.get(response, "message", false)) || _.get(resData,"message", false)
        if(messageErr) {
            data.cb(messageErr, false);
        } else if(resData){
            data.cb(false, resData);
        }
    } catch (e) {
        console.log("load grid error")
    }
}

export function* watchLoadDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2085_LOAD_DATA_GRID, loadDataGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadApprovalHistory(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2085/approve-history", data.params);
        const resData = _.get(response, "data", null);
        const resStatus = _.get(resData, "status", 1)
        if(resStatus) {
            const messageErr = _.get(response, "Message", _.get(response, "message", false)) || _.get(resData,"message", false)
            data.cb(true,messageErr || "Loi_khong_xac_dinh");
        } else {
            data.cb(false, "Xoa_thanh_cong");
        }
    } catch (e) {
        console.log("load grid error")
    }
}

export function* watchLoadApprovalHistory() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2085_LOAD_APPROVAL_HISTORY, loadApprovalHistory);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w29f2085/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load grid error")
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2085_DELETE, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* onCancel(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2085/cancel", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("cancel error")
    }
}

export function* watchOnCancel() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2085_CANCEL, onCancel);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

