/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 8/11/2022
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types }                    from "./W29F2061_actions";
import { delay }                    from "redux-saga";

export default function W29F2061Sagas() {
    return [
        watchLoadShift(),
        watchLoadImportOtBefore(),
        watchLoadCheckOtBefore(),
        watchLoadImportOtBet(),
        watchLoadCheckOtBet(),
        watchLoadImportOtAfter(),
        watchLoadCheckOtAfter(),
        watchLoadCheckOtHour(),
    ];
}

export function* loadShift(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2061/load-shift', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load shift error');
    }

}

export function* watchLoadShift() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2061_LOAD_SHIFT, loadShift);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadImportOtBefore(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2061/import-ot-before', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load Import Ot Before error');
    }

}

export function* watchLoadImportOtBefore() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2061_LOAD_IMPORT_OT_BEFORE, loadImportOtBefore);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* loadCheckOtBefore(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2061/check-ot-before', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load Check Ot Before error');
    }

}

export function* watchLoadCheckOtBefore() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2061_LOAD_CHECK_OT_BEFORE, loadCheckOtBefore);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadImportOtBet(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2061/import-ot-bet', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load Import Ot Bet error');
    }

}

export function* watchLoadImportOtBet() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2061_LOAD_IMPORT_OT_BET, loadImportOtBet);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadCheckOtBet(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2061/check-ot-bet', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load Check Ot Bet error');
    }

}

export function* watchLoadCheckOtBet() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2061_LOAD_CHECK_OT_BET, loadCheckOtBet);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadImportOtAfter(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2061/import-ot-after', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load import ot after error');
    }

}

export function* watchLoadImportOtAfter() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2061_LOAD_IMPORT_OT_AFTER, loadImportOtAfter);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadCheckOtAfter(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2061/check-ot-after', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load check ot after error');
    }

}

export function* watchLoadCheckOtAfter() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2061_LOAD_CHECK_OT_AFTER, loadCheckOtAfter);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadCheckOtHour(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2061/get-ot-hour', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('load check ot hour error');
    }

}

export function* watchLoadCheckOtHour() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2061_LOAD_CHECK_OT_HOUR, loadCheckOtHour);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}