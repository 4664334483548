/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/14/2020
 * @Example 
 * 
 */

import Api from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2309_actions";
import { delay } from "redux-saga";

export default function W29F2309Sagas() {
    return [
        watchLoadForm(),
        watchApproval(),
        watchCheck(),
        watchGetGridSetting(),
        watchCancelApproval(),
    ];
}

//load form
export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2308/load-form', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2309_LOAD_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get form data error');
    }
}

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2309_LOAD_FORM, loadForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//get grid setting
export function* getGridSetting(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w29f2072/load-grid-setting');
        if (response && response.data) {
            yield put({ type: types.W29F2309_GET_GRID_SETTING_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get grid setting error');
    }
}

export function* watchGetGridSetting() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2309_GET_GRID_SETTING, getGridSetting);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//approval
export function* approval(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w29f2308/approval', data.params);
        if (response && response.data) {
            yield put({ type: types.W29F2309_APPROVAL_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('approval error');
    }
}

export function* watchApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2309_APPROVAL, approval);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//approval
export function* cancelApproval() {
    try {
        yield delay(300);
        yield put({ type: types.W29F2309_CANCEL_APPROVAL_SUCCESS });
    }
    catch (e) {
        console.log('cancel error');
    }
}

export function* watchCancelApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2309_APPROVAL, cancelApproval);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//check store
export function* check(data) {
    try {
        yield delay(300);
        for (let i = 0; i < data.params.arrCheckParams.length; i++) {
            const response = yield Api.put('/w29f2308/check', data.params.arrCheckParams[i]);
            if (response && response.data) {
                yield put({ type: types.W29F2309_CHECK_SUCCESS, data: response.data });
                data.cb && data.cb(null, response.data);
                if (response.data.Status === 0) {
                    const approvalResponse = yield Api.put('/w29f2308/approval', data.params.arrApprovalParams[i]);
                    if (approvalResponse && approvalResponse.data) {
                        yield put({ type: types.W29F2309_APPROVAL_SUCCESS, data: approvalResponse.data });
                        data.cb && data.cb(null, approvalResponse.data)
                    }
                    else {
                        data.cb && data.cb(approvalResponse, null);
                    }
                }
                else if(response.data.Status === 1 && response.data.MsgAsk === 1){
                    yield take();
                }
            }
            else {
                data.cb && data.cb(response, null)
            }
        }
    }
    catch (e) {
        console.log('check error');
    }
}

export function* watchCheck() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2309_CHECK, check);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}