/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 8/11/2022
 * @Example 
 */
export const types = {
   
    W29F2061_LOAD_SHIFT: "W29F2061_LOAD_SHIFT",
    W29F2061_LOAD_IMPORT_OT_BEFORE: "W29F2061_LOAD_IMPORT_OT_BEFORE",
    W29F2061_LOAD_CHECK_OT_BEFORE: "W29F2061_LOAD_CHECK_OT_BEFORE",
    W29F2061_LOAD_IMPORT_OT_BET: "W29F2061_LOAD_IMPORT_OT_BET",
    W29F2061_LOAD_CHECK_OT_BET: "W29F2061_LOAD_CHECK_OT_BET",
    W29F2061_LOAD_IMPORT_OT_AFTER: "W29F2061_LOAD_IMPORT_OT_AFTER",
    W29F2061_LOAD_CHECK_OT_AFTER: "W29F2061_LOAD_CHECK_OT_AFTER",
    W29F2061_LOAD_CHECK_OT_HOUR: "W29F2061_LOAD_CHECK_OT_HOUR",

};

export function loadShift(params, cb) {
    return {
        type: types.W29F2061_LOAD_SHIFT,
        params,
        cb
    }
}

export function loadImportOtBefore(params, cb) {
    return {
        type: types.W29F2061_LOAD_IMPORT_OT_BEFORE,
        params,
        cb
    }
}

export function loadCheckOtBefore(params, cb) {
    return {
        type: types.W29F2061_LOAD_CHECK_OT_BEFORE,
        params,
        cb
    }
}

export function loadImportOtBet(params, cb) {
    return {
        type: types.W29F2061_LOAD_IMPORT_OT_BET,
        params,
        cb
    }
}

export function loadCheckOtBet(params, cb) {
    return {
        type: types.W29F2061_LOAD_CHECK_OT_BET,
        params,
        cb
    }
}

export function loadImportOtAfter(params, cb) {
    return {
        type: types.W29F2061_LOAD_IMPORT_OT_AFTER,
        params,
        cb
    }
}

export function loadCheckOtAfter(params, cb) {
    return {
        type: types.W29F2061_LOAD_CHECK_OT_AFTER,
        params,
        cb
    }
}

export function loadCheckOtHour(params, cb) {
    return {
        type: types.W29F2061_LOAD_CHECK_OT_HOUR,
        params,
        cb
    }
}