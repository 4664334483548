/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/7/2022
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest} from "redux-saga/effects";
import { types } from "../../W2X/W29F2120/W29F2120_actions";
import { delay } from "redux-saga";

export default function W29F2120Sagas() {
    return [
        watchLoadDate(),
        watchLoadLeaveType(),
        watchLoadTimeKeeping(),
        watchSave(),
    ];
}

export function* loadDate(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w29f2120/load-date", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('load date error');
    }
}

export function* watchLoadDate() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2120_LOAD_DATE, loadDate);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadLeaveType(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w29f2120/load-leave-type", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('load leave type error');
    }
}

export function* watchLoadLeaveType() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2120_LOAD_LEAVE_TYPE, loadLeaveType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadTimeKeeping(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w29f2120/load-time-keeping", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('load time keeping error');
    }
}

export function* watchLoadTimeKeeping() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2120_LOAD_TIME_KEEPING, loadTimeKeeping);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const res = yield Api.post("/w29f2120/save", data.params);
        if (res && res.data) {
            data.cb && data.cb(null, res.data);
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {
        console.log('load save error');
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2120_SAVE, save);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
