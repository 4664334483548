export const types = {
    W29F2085_LOAD_DATA_GRID: "W29F2085_LOAD_DATA_GRID",
    W29F2085_LOAD_APPROVAL_HISTORY: "W29F2085_LOAD_APPROVAL_HISTORY",
    W29F2085_DELETE: "W29F2085_DELETE",
    W29F2085_CANCEL: "W29F2085_CANCEL",
};

//get-date-by-tran
export function loadDataGrid(params, cb) {
    return {
        type: types.W29F2085_LOAD_DATA_GRID,
        params,
        cb
    }
}
//get-month-payroll
export function loadApprovalHistory(params, cb) {
    return {
        type: types.W29F2085_LOAD_APPROVAL_HISTORY,
        params,
        cb
    }
}
//get-detail-workday
export function deleteRow(params, cb) {
    return {
        type: types.W29F2085_DELETE,
        params,
        cb
    }
}

export function onCancel(params, cb) {
    return {
        type: types.W29F2085_CANCEL,
        params,
        cb
    }
}