/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/7/2022
 * @Example
 */

export const types = {
    W29F2300_GET_CBO_SHIFT: 'W29F2300_GET_CBO_SHIFT',
    W29F2300_GET_CBO_SHIFT_SUCCESS: 'W29F2300_GET_CBO_SHIFT_SUCCESS',
    W29F2300_GET_SALARY_GROUPS: 'W29F2300_GET_SALARY_GROUPS',
    W29F2300_GET_SALARY_GROUPS_SUCCESS: 'W29F2300_GET_SALARY_GROUPS_SUCCESS',
    W29F2300_GET_CRITERIA: 'W29F2300_GET_CRITERIA',
    W29F2300_GET_CRITERIA_SUCCESS: 'W29F2300_GET_CRITERIA_SUCCESS',
    W29F2300_GET_GRID_DETAIL: 'W29F2300_GET_GRID_DETAIL',
    W29F2300_SAVE: 'W29F2300_SAVE',

};

export function getCboShift(params, cb) {
    return {
        type: types.W29F2300_GET_CBO_SHIFT,
        params,
        cb
    }
}

export function getSalaryGroups(params, cb) {
    return {
        type: types.W29F2300_GET_SALARY_GROUPS,
        params,
        cb
    }
}

export function getCriteria(params, cb) {
    return {
        type: types.W29F2300_GET_CRITERIA,
        params,
        cb
    }
}

export function getGridDetail(params, cb) {
    return {
        type: types.W29F2300_GET_GRID_DETAIL,
        params,
        cb
    }
}

export function save(params, cb) {
    return {
        type: types.W29F2300_SAVE,
        params,
        cb
    }
}
