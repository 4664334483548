/**
 * @copyright 2023 @ DigiNet
 * @author TRANGHOANG
 * @create 18/01/2023
 * @Example
 */

import Api from "../../../services/api";
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W29F2060_actions";
import { delay } from "redux-saga";
import Config from "../../../config";

export default function W29F2061Sagas() {
    return [watchLoadForm(), watchLoadShift(), watchDeleteRow(), watchExportLeaveOfAbsenceForm()];
}

export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2060/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load form error");
    }
}

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2060_LOAD_FORM, loadForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadShift(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2060/load-shift", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("load shift error");
    }
}

export function* watchLoadShift() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2060_LOAD_SHIFT, loadShift);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w29f2060/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete error");
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2060_DELETE_ROW, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return (filename = decodeURIComponent(reFileName));
    }
    return filename; // else return original data
}

export function* exportLeaveOfAbsenceForm(data) {
    try {
        yield delay(300);
        const response = yield Api.putExport(
            "/w29f2060/export-ot-of-absence-form?TransID=" + data.params.TransID,
            null,
            "GET"
        );
        if (response && response.data) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null);
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename
                    ? checkUrlFileName(response.filename)
                    : "data-export-hr-" + datetime + ".xls";
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("export-leave-of-absence-form error");
    }
}

export function* watchExportLeaveOfAbsenceForm() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2060_EXPORT_LEAVE_OF_ABSENCE_FORM, exportLeaveOfAbsenceForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
